import React, { Component } from "react";
import { CheckCircleIcon, UserCircleIcon, StarIcon } from "@heroicons/react/24/solid";
import styled from "styled-components";
import Logo from "./images/wordwell-logo.png";
import Daimond from "./images/diamond-sharp.svg";
import config from "./config";

import { computed } from "mobx";
import { observer, inject } from "mobx-react";
@inject("store")
@observer
class Pricing extends Component {

  @computed get reviews() {
    return [
      {
        text:"I was completely stuck trying to write my vows. Wordwell helped me get my feelings down on the page and made the whole process easy. The AI rewrites helped me express exactly how I felt, and I could personalize everything to make it truly mine. In the end, my vows were heartfelt, personal, and perfect for the day.",
        name:"Olivia S",
        type:"Bride"
      },
      {
        text:"Getting started was the hardest part for me. Wordwell made it quick and easy to pull together a speech I felt proud of. Their platform gave me all the ideas and structure I needed – saving me hours of stress.",
        name:"Daniel H",
        type:"Groom"
      },
      {
        text:"I had no clue what to include in my speech. Wordwell’s prompts helped me tease out the perfect moments to share, and the AI rewrites were short, clear, and felt like my own words. It saved me so much time!",
        name:"Chris B",
        type:"Best Man"
      },
      {
        text:"Writer’s block hit me hard when trying to write my speech. Wordwell not only got me past that but helped shape my speech into something that felt truly personal. The AI rewrites were amazing for perfecting the tone and flow, and I made a few edits to add my own flair.",
        name:"Mia T",
        type:"Maid of Honour"
      },
      {
        text:"I’ve never written a speech before and was freaking out. Wordwell turned my rough ideas into a cracking draft! With their feedback system, I kept improving it until I had the perfect mix of humour and emotion. Highly recommend!",
        name:"David P",
        type:"Best Man"
      },
      {
        text:"Writing a speech as the groom had me stressing for weeks. Wordwell helped me express my thoughts and feelings perfectly. The AI revisions were awesome, and I loved how I could manually edit the speech in the app.",
        name:"Tom R",
        type:"Groom"
      },
      {
        text:"I was overwhelmed trying to write my wedding vows. Wordwell made it easy to say what I wanted to. The end result felt personal and true to me.",
        name:"Emma L",
        type:"Bride"
      },
      {
        text:"Wordwell took the pressure off completely. I wasn’t sure what to say or what was expected in my speech. Their prompts made sure I covered all the right things, and I could give feedback to fine-tune everything. Saved me so much time!",
        name:"Jessica P",
        type:"Mother of the Groom"
      },
      {
        text:"I was hesitant about using AI for my speech, but Wordwell’s money-back guarantee convinced me to try. I expected a basic draft, but I was pleasantly surprised. Wordwell helped me include personal stories and put my feelings into words. The process was fast, and the easy editing helped me nail the tone.",
        name:"John D",
        type:"Father of the Groom"
      },
      {
        text:"We both used Wordwell – I wrote my speech and James used it for his vows. It was so quick! The prompts and drafts got us started fast, and the revisions made everything feel truly personal. It took the stress out of the process completely!",
        name:"Anna & James L",
        type:"Bride & Groom"
      },
      {
        text:"AI revisions were clutch. The process generated a quality draft so quickly, and I used the revisions to adjust a few points and put my stamp on it. Wordwell made the whole experience easy and fun.",
        name:"Callum H",
        type:"Best Man"
      },
      {
        text:"Wordwell saved me a ton of time! I didn’t know what I needed to cover in my speech or what the conventions were. Their tailored questions prompted me for all the ingredients, and they generated the drafts so fast. I could give feedback to tighten everything up. Really great!",
        name:"Isabella R",
        type:"Maid of Honour"
      },
      {
        text:"I wasn’t sure how to write my vows, but Wordwell’s AI did all the heavy lifting. It prompted me with the perfect questions and generated a beautiful draft in minutes. I could tweak it easily, and the result was more than I imagined!",
        name:"Lucy R",
        type:"Bridesmaid"
      },
      {
        text:"I was terrified of giving a speech! Wordwell helped me every step of the way. Their platform allowed me to tweak the speech until it felt ‘me’ – and I loved having the AI do the work with rewrites based on my suggestions.",
        name:"Sophie K",
        type:"Bridesmaid"
      },
      {
        text:"Wordwell made my speech stand out. The AI rewrites and the option to edit manually gave me total control, and it took the stress away. Everyone complimented how personal and funny it was!",
        name:"Rory T",
        type:"Groomsman"
      },
    ];
  };

  render() {
    const redirect = new URLSearchParams(window.location.search).get(
      "redirect"
    );
    const type = new URLSearchParams(window.location.search).get("type");
    return (
      <>
        {this.props.store.profile.status === "active" ? null : (
          <div className="border-b border-gray-300 bg-white shadow-sm ">
            <div className="container flex mx-auto px-4 md:px-28 flex select-none">
              <div className="relative  focus-within:text-green-500 flex flex-1 "></div>
              <div
                onClick={this.props.store.handleLogout}
                className="cursor-pointer text-lg flex py-3 px-6 xl:py-4 xl:px-8 hover:bg-gray-100 rounded-t-md font-medium transition items-center"
              >
                <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
                <div className="hidden lg:block"> Log Out</div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`container mx-auto select-none ${
            this.props.store.profile.status === "active"
              ? ""
              : "px-8 py-4 lg:px-28 lg:py-12"
          }`}
        >
          {this.props.store.profile.status === "active" ? (
            <>
              <p className="text-lg sm:text-xl md:text-2xl  mb-14 mt-2 text-center">
                {this.props.store.profile.plan === "tbc"
                  ? "Upgrade now to instantly access your full speech/vows and additional features to refine your speech."
                  : "Upgrade to unlock additional features and revisions."}
                <br />
                All prices are listed in US dollars.
              </p>
            </>
          ) : (
            <>
              <div className="text-center">
                <img className="inline" src={Logo} width={250} alt="logo" />
              </div>
              <PricingText>
                <p className="text-lg mb-10 mt-2 text-center">
                  Our Premium Package delivers first-class quality with unbeatable value. Backed by a <strong>100% money-back guarantee</strong>, so you can create the perfect speech with confidence.
                </p>
              </PricingText>
            </>
          )}

          <Grid>
            {/* {this.props.store.profile.status === "inactive" ? (
              <Tbc
                fromColor="gray-400"
                toColor="gray-500"
                baseURL={this.props.store.baseURL}
                api={this.props.store.api}
              />
            ) : null} */}
            {/* {this.props.store.profile.status === "inactive" ||
            this.props.store.profile.plan === "tbc" ? (
              <Essential
                fromColor="green-400"
                toColor="green-600"
                baseURL={this.props.store.baseURL}
                api={this.props.store.api}
                plan={this.props.store.profile.plan}
                redirect={redirect}
              />
            ) : null} */}
            {this.props.store.profile.status === "inactive" ||
            this.props.store.profile.plan === "tbc" ||
            this.props.store.profile.plan === "entry" ? (
              <Premium
                fromColor="indigo-500"
                toColor="red-500"
                baseURL={this.props.store.baseURL}
                api={this.props.store.api}
                plan={this.props.store.profile.plan}
                redirect={redirect}
              />
            ) : null}
          </Grid>
          <Testimonials>
            <div className="w-full my-10">
              <div className="testimonials-title my-5 text-center">Success stories from happy customers</div>
              <div className="testimonials-para text-lg my-5 text-center flex justify-center"><p>Wordwell has helped more than 1,000 customers create unforgettable wedding moments. Their stories could be yours.</p></div>
              <div className="testimonials-wrapper">
                {this.reviews.map((review, index) => (
                  <div className="testimonial"> 
                    <div className="stars"><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></div>
                    <div className="testimonial-text">{review.text}</div>
                    <div className="testimonial-user">{review.name} <CheckCircleIcon/></div>
                    <div className="testimonial-type">{review.type}</div>
                  </div>
                ))}
              </div>
            </div>
          </Testimonials>
        </div>
      </>
    );
  }
}

const Tbc = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative order-1 xl:order-1">
    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg  border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105   flex-1`}
    >
      <div className="p-5 flex-1">
        <div className="flex flex-col h-full justify-between">
          <div>
            <div
              href="#"
              className={`text-${
                fromColor ? fromColor : "green-500"
              } block text-lg text-2xl leading-tight font-medium mb-2`}
            >
              Free Trial
            </div>
            <div className="text-5xl  font-bold">FREE</div>

            <div className="divide-y divide-dashed divide-gray-300 mt-4">
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>
                  Risk Free: Craft a stunning speech or heartfelt vows in
                  minutes
                </div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>Preview one-third of your speech or vows as a sample</div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>
                  Upgrade for full access and premium tools to polish your
                  speech
                </div>
              </div>
            </div>
          </div>

          <form
            action={baseURL + "user/stripe/tbc"}
            method="POST"
            className="flex "
          >
            <input
              type="hidden"
              name="token"
              value={api.defaults.headers.common["x-access-token"]}
            />
            <button
              type="submit"
              className={`mt-8 inset-0 bg-gradient-to-r from-${
                fromColor ? fromColor : "green-400"
              } to-${
                toColor ? toColor : "blue-500"
              } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
            >
              Try Now
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
);

const Essential = ({ fromColor, toColor, baseURL, api, plan, redirect }) => (
  <div className="flex relative order-1 xl:order-1">
    <div
      className="absolute bg-gradient-to-r from-green-400 to-green-600 shadow-lg text-white text-sm font-medium text-center rounded-full px-2 py-1 inline-block"
      style={{
        top: "-15px",
        left: "50%",
        transform: "translate(-50%, 0px)",
        zIndex: "9",
        width: "150px",
      }}
    >
      Most popular plan
    </div>
    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg  border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105   flex-1`}
    >
      <div className="p-5 flex-1">
        <div className="flex flex-col h-full justify-between">
          <div>
            <div
              href="#"
              className={`text-${
                fromColor ? fromColor : "green-500"
              } block text-lg text-2xl leading-tight font-medium mb-2`}
            >
              Essentials
            </div>
            <div className="text-5xl  font-bold">$14</div>

            <div className="divide-y divide-dashed divide-gray-300 mt-4">
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>
                  Personalized wedding speech{" "}
                  <span className="font-medium ">OR</span> vows
                </div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div className="font-medium ">1 AI-powered re-write</div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>Unlimited manual edits</div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>One-click download</div>
              </div>
              <div className="py-2 flex  items-center">
                <CheckCircleIcon
                  className={`w-6 h-6 mr-2 text-${
                    fromColor ? fromColor : "green-500"
                  }`}
                  style={{ flex: "0 0 24px" }}
                />
                <div>Ultimate Wedding Speech & Delivery Guidebook</div>
              </div>
            </div>
          </div>

          <form
            action={baseURL + "user/stripe/payment"}
            method="POST"
            className="flex "
          >
            <input
              type="hidden"
              name="token"
              value={api.defaults.headers.common["x-access-token"]}
            />
            <input
              type="hidden"
              name="priceId"
              value={config.stripe.essentials}
            />
            <input type="hidden" name="redirect" value={redirect} />
            {/* <input type="hidden" name="trial" value="true" /> */}
            <button
              type="submit"
              className={`mt-8 inset-0 bg-gradient-to-r from-${
                fromColor ? fromColor : "green-400"
              } to-${
                toColor ? toColor : "blue-500"
              } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
            >
              {plan === "tbc" ? "Upgrade Now" : "Get Started"}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
);

const Premium = ({ fromColor, toColor, baseURL, api, plan, redirect }) => (
  <div className="flex relative max-w-lg w-full order-2 xl:order-2">
    <PriceCard>
      <div
        className={`price-card-main bg-white overflow-hidden md:flex relative flex-1`}
      >
        <div className="p-7 flex-1">
          <div className="flex flex-col h-full justify-between">
            <div>

              <div className="price-title-wrapper">
                <div className="price-icon-block">
                <img className="inline" src={Daimond} width={250} alt="daimond-icon" />
                </div>
                <div className="price-title">
                  <div className="price-heading">Premium</div>
                  <div className="price-text">For speech, vows, and custom messages</div>
                </div>
              </div>

              <div className="pricing-line-break"></div>

              <div className="pricing-heading">
                {plan === "entry" ? "$5" : "$29.99"}
                <span> One-time payment</span>
              </div>

              <div className="mt-4 md:text-xl text-base">

                <div className="py-2">
                  ✔ A personalized wedding speech - tailored to your role
                </div>
                <div className="py-2">
                  ✔ Custom-crafted wedding vows
                </div>
                <div className="py-2">
                  ✔ A bespoke card message
                </div>
                <div className="py-2">
                  ✔ Unlimited AI rewrites & on-platform edits
                </div>
                <div className="py-2">
                  ✔ Instant download
                </div>
                <div className="py-2">
                  ✔ Wedding Speech Delivery Guidebook
                </div>
                <div className="py-2">
                  ✔ 100% money-back satisfaction guarantee
                </div>

              </div>
            </div>
            <form
              action={baseURL + "user/stripe/payment"}
              method="POST"
              className="flex"
            >
              <input
                type="hidden"
                name="token"
                value={api.defaults.headers.common["x-access-token"]}
              />
              <input
                type="hidden"
                name="priceId"
                value={
                  plan === "entry"
                    ? config.stripe.premiumUpgrade
                    : config.stripe.premium
                }
              />
              <input type="hidden" name="redirect" value={redirect} />
              <button
                type="submit"
                className={`price-card-button mt-8 flex-1 font-medium text-center text-xs`}
              >
                {plan === "tbc" || plan === "entry"
                  ? "UPGRADE NOW"
                  : "START WRITING"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </PriceCard>
  </div>
);

const Grid = ({ children }) => (
  <div className="flex justify-center grid-cols-1 gap-6 mt-4 xl:grid-cols-3">{children}</div>
);

const PricingText = styled.div`
  display: flex;
  justify-content: center;

  p {
    max-width: 720px;
  }

  strong {
    background-color: #a0a48e;
    color: #fff;
    padding: 0px 6px;
    font-weight: 400;
  }
`;
const PriceCard = styled.div`
  width: 100%;

  .price-card-main {
    box-shadow: none;
  }
  .price-card-main:hover {
    box-shadow: 0 0 0 1px #de967d, 0 5px 20px -9px #00000061;
  }  

  .price-title-wrapper {
    display: flex;
  }

  .price-icon-block {
    width: 60px;
    min-width: 60px;
    height: 60px;
    min-height: 60px;
    margin-right: 20px;
    padding: 10px;
    display: flex;
    border: 2px none #545748;
    background-color: #f6f4ec;
  }

  .price-heading {
    color: #545748;
    font-size: 50px;
    margin-bottom: 24px;
    line-height: normal;
  }

  .price-text {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
  }

  .pricing-line-break {
    border: 1px solid #ede9db;
    color: #de967d;
    background-color: #575757;
    height: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .pricing-heading {
    font-size: 40px;
    font-weight: 400;
    text-align: center;
  }

  .pricing-heading span {
    font-size: 18px;
  }

  .price-card-button {
    border: 1px solid #de967d;
    font-family: Montserrat, sans-serif;
    padding: 18px 30px;
    transition: box-shadow .4s cubic-bezier(.25,.46,.45,.94), background-color .4s cubic-bezier(.25,.46,.45,.94), color .4s cubic-bezier(.25,.46,.45,.94);
  }

  .price-card-button:hover {
    background-color: #de967d;;
    box-shadow: 0 12px 50px -5px #de967d;;
    color: #fff;
  }

  @media (max-width: 600px) {
    .price-heading {
      font-size: 38px;
    }
    pricing-heading {
      font-size: 32px;
    }
  }
`;
const Testimonials = styled.div`
  .testimonials-title {
    color: #545748;
    font-size: 40px;
    line-height: normal;
    font-weight: 600;
  }
  .testimonials-para p {
    max-width:720px;
  }
  .testimonials-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: center;

    .testimonial {
      flex: 0 0 100%;
      max-width: 720px;
      background-color: #fff;
      border-radius: 15px;
      padding: 25px;
      margin-bottom: 25px;
      box-shadow: 0 0 0 1px #fff,0 5px 25px -15px #00000061;

      .stars {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
          margin-left: 4px;
          color: #fbbf24;
        }
      }
    }

    .testimonial-text {
      margin: 15px 0px;
    }
    .testimonial-user {
      font-weight: 600;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        color: #2664ec;
      }
    }
    .testimonial-type {
    
    }
  }
`;

export default Pricing;
